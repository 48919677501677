import React from 'react'

import Features from '../views/features'

const FeaturesPage = () => {
  return (
    <div>
        <Features />
    </div>
  )
}

export default FeaturesPage