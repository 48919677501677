import React from "react"; 
import { Link } from "react-router-dom";

const HeroSection = ({ data, images }) => {
  return (
    <div className="bg-white w-full overflow-x-hidden">
      <div
        style={{ backgroundImage: `url(${images?.bizheroimage})` }}
        className="w-full min-h-[35rem] px-[1.5rem] md:px-[3.9rem] flex flex-col items-center bg-no-repeat bg-cover bg-center "
      >
      
      <div className="min-h-[17.6rem] w-full text-[#353E44] text-center flex flex-col items-center mx-auto px-[1.5rem] md:px-[3.9rem]">
        <h1 className="mt-[4.6rem] font-[800] text-[1.5rem] md:text-[2.875rem] leading-[1.7rem] md:leading-[3.303rem] w-auto max-w-[51rem]">
          {data.title}
        </h1>
      </div>
      {/* image */}
      
        <p className="font-[400] text-center text-[1rem] md:text-[1.3rem] leading-[1.8rem] md:leading-[2.1rem] w-auto max-w-[42.2rem]">
          {data.desc}
        </p>
        <Link
          to="/signup"
          className="flex items-center mt-[0.94rem] w-full md:max-w-[19.7rem] h-[3.5rem] font-[600] bg-[#2293DF] active:opacity-[0.5] rounded-[0.6rem] py-[0.9rem] px-[0.7rem] border border-solid border-[#2293DF] shadow-md shadow-[#2293DF] text-white text-[1.5rem] leading-[1.8rem] "
        >
          {data.action}
          <img src={images?.leftarrow} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default HeroSection;
