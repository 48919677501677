import Brand from '../assets/images/Brand.png'
import androidstore from '../assets/images/androidstore.png'
import Frame3 from '../assets/images/Frame3.png'
import leftarrow from '../assets/svg/leftarrow.svg'
import Hamburger from '../assets/svg/Hamburger.svg'
import navlogo from '../assets/images/navlogo.png'
import ios from '../assets/svg/ios.svg'


// eslint-disable-next-line
export default{
images:{
    Brand,
    leftarrow,
    navlogo,
    Hamburger,
    ios,
    androidstore,
    Frame3

},
getStarted:{
    title: 'Ready to get started?',
    subtitle: 'Start making all your payments, the right way.',
    desc: 'Get setup in minutes',
    action: 'Let’s get started'
},
footerNav:{
    navs: [
        {
            name: 'Key Features',
            to: '/features'
        },
        {
            name: 'Business Solutions',
            to: '/business-solutions'
        },
        {
            name: 'FAQs',
            to: '/faq'
        },
        {
            name: 'Support',
            to: '/support'
        },
    ],
    brandName: '© MPayer Technologies Limited 2022'
},
desktopNav:{
    navs: [
        {
            name: 'Home',
            to: '/'
        },
        {
            name: 'Features',
            to: '/features'
        },
    ], 
    logo: navlogo
},
mobileNav:{
    navs: [
        {
            name: 'Home',
            to: '/'
        },
        {
            name: 'Features',
            to: '/features'
        },
    ],
    logo: navlogo
},
altDesktopNav:{
    navs: [
        {
            name: 'Key Features',
            to: '/features'
        },
        {
            name: 'Business Solutions',
            to: '/business-solutions'
        },
    ], 
    logo: navlogo
},
altMobileNav:{
    navs: [
        {
            name: 'Key Features',
            to: '/features'
        },
        {
            name: 'Business Solutions',
            to: '/business-solutions'
        },
    ],
    logo: navlogo
},
moneyNeeds:{
    title: 'The easiest way to pay your bills and meet all your money needs',
    desc: 'MPayer is a personal finance technology platform offering user easy, quick and secure access to important financial and business services and tools',
}
}