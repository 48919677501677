import React from 'react'
import Footer from '../components/Footer'

import AppHeader from '../components/AppHeader'

const Main = ({children}) => {
  return (
    <>
        {/* <h1>NAVBAR LOADING...</h1> */}
        <AppHeader />
        <main>{children}</main>
        <Footer />
    </>
  )
}

export default Main