import React from 'react'
import mocks from './mocks'

import Alt from '../../layouts/Alt'

import HeroSection from './HeroSection'
import SalesTerminal from './SalesTerminal'
import CollectPayments from './CollectPayments'
import OptionsAndChannels from './OptionsAndChannels'
import PowerUp from './PowerUp'

const BusinessSolutions = () => {
  return (
    // data={mocks.heroSection} images={mocks?.images}
    <Alt>
        <HeroSection data={mocks.heroSection} images={mocks?.images} />
        <SalesTerminal data={mocks.salesTerminal} images={mocks?.images} />
        <CollectPayments data={mocks.collectPayments} images={mocks?.images} />
        <OptionsAndChannels data={mocks.optionsAndChannels} images={mocks?.images} />
        <PowerUp data={mocks.powerUp} images={mocks?.images} />

    </Alt>
  )
}

export default BusinessSolutions