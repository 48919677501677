import React from 'react'
import mocks from './mocks'

import ReverseMerchantsCard from '../../components/cards/ReverseMerchantsCard'

const ExpensiveHardware = () => {
  const customStyle = { top: '15rem', left: '3.5rem', transform: 'rotate(45deg)' } 
  // top-[14rem] right-[4rem]
  return (
    <div className='mt-[5.8rem] md:mt-[11.63rem]'>
        <ReverseMerchantsCard data={mocks.merchantsCard} images={mocks?.images} style={customStyle} />
    </div>
  )
}

export default ExpensiveHardware