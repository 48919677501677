import React from "react";
import { Link } from "react-router-dom";

const Purchases = ({ data, images }) => {
  return (
    <div className="text-[#353E44] w-full mt-[2.45rem] lg:mt-[4.9rem] flex flex-col items-center px-[2.5rem] lg:px-[7.6rem] pb-[2.3rem]    ">
      <h2 className="text-center font-[600] text-[2.25rem] leading-[2.59rem] w-full max-w-[60.6rem]  ">
        {data.title}
      </h2>
      <h3 className="mt-[2.06rem] w-full text-center max-w-[36.8rem] font-[700] text-[1.8rem] leading-[2.67rem] ">
        {data.subtitle}{" "}
      </h3>
      <p className="mt-[0.19rem] font-[500] text-center text-[1.25rem] leading-[2.13rem] w-full max-w-[45.7rem]    ">
        {data.desc}{" "}
      </p>
      <Link to={data.path} className="mt-[0.19rem] text-[#2293DF] text-[1.125rem] font-[700] leading-[1.7rem] text-center cursor-pointer active:opacity-[0.5] ">
        {data.action}{" "}
      </Link>
      <div className="mt-[3.63rem] w-full flex flex-col lg:flex-row space-x-0 lg:space-x-[3.3rem] items-center ">
        <div className="">
          <img src={images.purchaseposg} alt="" />
        </div>

        <div className="mt-[3.3rem] lg:mt-0 ">
          <div className="absolute z-[-1] top-[210.5rem] left-[50.3rem] max-w-[44.4rem] min-h-[46.5rem] hidden lg:block ">
            <img src={images.purchaseblob} alt="" />
          </div>

          <div className="text-[#353E44]  ">
            <h2 className="text-[1.88rem] font-[700] leading-[2.67rem] ">
              {data.item_title}
            </h2>
            <p className="text-[1.25rem] font-[500] leading-[2.13rem] w-full max-w-[30.6rem]  ">
              {data.item_desc}
            </p>
            <div className="mt-[2.7rem] space-y-[1.8rem]   ">
              {data.items.map((item) => (
                <div key={item} className="flex items-center ">
                  <img src={images.tick} alt="" className="mr-[2.3rem]" />
                  <p className="font-[500] text-[1.125rem] leading-[2.13rem]   ">
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center mt-[3.06rem] text-center space-x-0 md:space-x-[4rem]  ">
        <p className="text-[#353E44] font-[600] text-[2.25rem] leading-[2.6rem] w-full max-w-[60.5rem]   ">
          {data.base_header}
        </p>
        <Link to="/signup" className="flex flex-row items-center justify-center px-[1.25rem] py-[0.88rem] w-[16.5rem] h-[3.5rem] bg-[#2293DF] rounded-[0.6rem] text-white font-[700] text-[1.125rem] leading-[1.7rem] ">
          {data.base_action}
          <img src={images.leftarrow} alt="" />
        </Link>
      </div>
    </div>
  );
};

export default Purchases;
