import React from 'react'
import ReverseInfoCard from '../../components/cards/ReverseInfoCard'

import mocks from './mocks'

const FinancialServices = () => {
  const customstyle = {backgroundColor: '#F5F7FA' };
  return (
    <div>
        <ReverseInfoCard data={mocks.financialServices} images={mocks?.images} style={customstyle} />
    </div>
  )
}

export default FinancialServices