import React from 'react'
import mocks from './mocks'

import PaymentCard from '../../components/cards/PaymentCard' 

const CollectPayments = ({data}) => {
    const card1 = {backgroundColor: 'rgba(34, 147, 223, 0.2)',  };
    const card2 = {backgroundColor: 'rgba(117, 189, 56, 0.2)' };
    // opacity: 0.2
  return (
    <div className='w-full px-[3.8rem] md:px-[7.6rem] mt-[5.8rem] overflow-x-hidden '>
        <h1 className='text-center w-auto max-w-[58.56rem] text-[2.45] md:text-[2.75rem] leading-[1.58rem] md:leading-[3.16rem] font-[700] text-[#000000] '>{data.title}</h1>
        <div className='mt-[3rem] flex flex-col lg:flex-row lg:space-x-[2.63rem] space-x-0 space-y-[2.63rem] lg:space-y-0 '>
            <PaymentCard data={mocks.paymentCard1} images={mocks?.images} style={card1} />
            <PaymentCard data={mocks.paymentCard2} images={mocks?.images} style={card2} />
        </div>
    </div>
  )
}

export default CollectPayments