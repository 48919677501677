import React from 'react'

import mocks from './mocks'

import ReverseMerchantsCard from '../../components/cards/ReverseMerchantsCard'

const AcceptPayments = () => {
  const customStyle = { display: 'none' } 
  return (
    <div className='mt-[5.4rem] md:mt-[7.4rem] mb-[6rem] md:mb-[12rem]'>
        <ReverseMerchantsCard data={mocks.acceptPayments} images={mocks?.images} style ={customStyle} />
    </div>
  )
}

export default AcceptPayments