import React from 'react'
import SignupView from "../views/auth/SignupView"

const SignUp = () => {
  return (
    <div>
      <SignupView />
    </div>
  )
}

export default SignUp