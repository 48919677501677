import React from 'react'
 
import LoginView from "../views/auth/LoginView"


const Login = () => {
  return (
    <div>
      <LoginView />
    </div>
  )
}

export default Login