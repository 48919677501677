import React from "react";
import { Link } from "react-router-dom";

const MerchantsCard = ({ data, images, style }) => {
  return (
    <div className="w-full px-[1.63rem] md:px-[3.25rem] flex flex-col lg:flex-row ite text-center md:text-left space-x-0 lg:space-x-[6.56rem]  ">
      <div className="">
        <div className="text-[1.44rem] md:text-[2.88rem] leading-[2.28rem] md:leading-[4.55rem] text-[#353E44] w-full max-w-[33.3rem] font-[700] ">
          {data.title}
        </div>
        <div className="mt-[1.25rem] text-[1rem] md:text-[1.5rem] leading-[2rem] md:leading-[2.3rem] text-[#333333] font-[400] w-full max-w-[30.8rem]">
          {data.desc}
        </div>
        <Link
          to="/signup"
          className="mt-[3.3rem] active:opacity-[0.5] bg-[#2293DF]  w-full max-w-[18.8rem] h-[3.94rem] rounded-[0.5rem] py-[0.9rem] px-[1.4rem] shadow-md shadow-[#7ED957] border border-solid border-[#7ED957] text-white text-[1.5rem] leading-[1.8rem] font-[600] flex justify-center items-center "
        >
          {data.action}
          <img src={images.leftarrow} alt="" className="ml-[3.63rem]" />
        </Link>
      </div>
      <div className="mt-[4.4rem] lg:mt-0 ">
        <div className="relative ">
          <div
            style={style}
            className="absolute z-[-1] overflow-visible hidden lg:block"
          >
            <img src={images.merchblob} alt="" />
          </div>
          <div className="">
            <img src={data.image} alt="" className="" />
            {/* w-[41.56rem] h-[33.05rem] width={665} height={528.89}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantsCard;
