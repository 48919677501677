import React from 'react'
import Individuals from '../views/individuals'

const IndividualsPage = () => {
  return (
    <div>
      <Individuals/>
    </div>
  )
}

export default IndividualsPage