import React from 'react'
import AltFooter from '../components/Footer'

import AltHeader from '../components/AltHeader'

const Alt = ({children}) => {
  return (
    <> 
        <AltHeader />
            <main>{children}</main>
        <AltFooter />
    </>
  )
}

export default Alt