import React from "react";
import { Link } from "react-router-dom";

const OptionsAndChannels = ({ data, images }) => {
  return (
    <div className="w-full mt-[5.75rem] px-[3.8rem] md:px-[7.623rem] ">
      <h1 className="text-black font-[700] text-[1.38rem] md:text-[2.75rem] leading-[1.58rem] md:leading-[3.16rem] ">
        {data.title}
      </h1>
      <div className="mt-[1.85rem] md:mt-[3.69rem] items-center flex flex-col lg:flex-row space-x-0 lg:space-x-[3rem] space-y-[3rem] lg:space-y-0 ">
        <div className="max-w-[32.3rem]">
          <h2 className="font-[600] text-black text-[1.88rem] leading-[2.15rem] ">
            {data.subtitle}
          </h2>
          <p className="mt-[0.63rem] md:mt-[1.25rem] text-[#353E44] font-[500] text-[1.125rem] leading-[2.13rem]">
            {data.desc}
          </p>
          <Link to={data.path} className="mt-[1.85rem] md:mt-[3.69rem] text-[#75BD38] font-[700] text-[1.125rem] leading-[1.73rem] cursor-pointer ">
            {data.action}
          </Link>
        </div>
        <div>
          <img src={images.fabric} alt="" width={588} height={323} />
        </div>
      </div>
    </div>
  );
};

export default OptionsAndChannels;
