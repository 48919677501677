import React from 'react'
import DesktopNav from '../components/DesktopNav'
import MobileNav from '../components/MobileNav'

import mocks from './mocks'

const AltHeader = () => {
  const bgColor = {backgroundColor: '#75BD38', }; 
  return (
    <div>
        <div className='hidden md:block'>
          <DesktopNav data={mocks.altDesktopNav} images={mocks?.images} style={bgColor} />
        </div>
        <div className='block md:hidden'>
          <MobileNav data={mocks.altMobileNav} images={mocks?.images} /> 
        </div>
    </div>
  )
}

export default AltHeader