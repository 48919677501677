import React from 'react'
import mocks from './mocks'

import MerchantsCard from '../../components/cards/MerchantsCard'

const GrowBusiness = () => {
  const customStyle = { top: '10rem', left: 'rem', transform: 'rotate(270deg)' }
  return (
    <div className='mt-[7.1rem] md:mt-[14.19rem]'>
        <MerchantsCard data={mocks.growBusiness} images={mocks?.images} style={customStyle} />
    </div>
  )
}

export default GrowBusiness