import React from 'react'
import InfoCard from '../../components/cards/InfoCard'

import mocks from './mocks'

const ContactlessPayment = () => {
  const customstyle = {backgroundColor: 'white' };
  return (
    <div  className="text-center">
        <InfoCard data={mocks.contactlessPayment} images={mocks?.images} style={customstyle} />
    </div>
  )
}

export default ContactlessPayment