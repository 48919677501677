import React from 'react'
import ReverseInfoCard from '../../components/cards/ReverseInfoCard'

import mocks from './mocks'

const MicroLending = () => {
  const customstyle = {backgroundColor: 'white' };
  return (
    <div>
        <ReverseInfoCard data={mocks.microLending} images={mocks?.images} style={customstyle} />
    </div>
  )
}

export default MicroLending