import React from "react";
import { Link } from "react-router-dom";

const EasyLife = ({ data, images }) => {
  return (
    <div className="mt-[2rem] lg:mt-[8rem] w-full px-[2.5rem] lg:px-[7.63rem] flex flex-col items-center ">
      <h3 className="font-[700] text-[#353E44] text-[1.88rem] leading-[2.15rem] text-center ">
        {data.title}
      </h3>
      <p className="font-[400] text-[1.125rem] leading-[1.63rem] text-black text-center w-full max-w-[53.1rem] ">
        {data.desc}
      </p>
      <div className="mt-[1.63rem] flex flex-col items-center lg:flex-row  space-x-0 lg:space-x-[1.94rem] ">
        <div className="">
          <img src={images.easyfabmain} alt="" />
        </div>

        <div className="lg:w-[65%] space-y-[1.56rem]  bg-[#2293DF] w-full max-w-[35.4rem]  px-[1.25rem] pt-[3.1rem] pb-[0.6rem]">
          {data.items.map((item, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row items-start  "
            >
              <img src={item.image} alt="" className="md:mr-[0.6rem] mr-0" />
              <div className="mt-[0.6rem] md:mt-0 text-white w-full max-w-[21.3rem]">
                <h3 className="font-[700] text-[1.5rem] leading-[1.7rem] ">
                  {item.title}
                </h3>
                <p className="font-[400] text-[1rem] leading-[2.13rem] w-full ">
                  {item.desc}
                </p>
                <Link to={item.path} className="cursor-pointer font-[700] text-[1.125rem] leading-[1.7rem] ">
                  {item.action}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EasyLife;
