import React from "react"; 
import { Link } from "react-router-dom";

const HeroSection = ({ data, images }) => {
  return (
    <div className="bg-white w-full "> 
      
      <div
        style={{ backgroundImage: `url(${images?.heroimage})` }}
        className=" w-full min-h-[34rem] shadow-inner shadow-[white] text-center px-[1.5rem] md:px-[3.9rem] bg-no-repeat bg-cover bg-center"
      >
      <div className="min-h-[14.6rem] px-[1.9rem] lg:px-[7.63rem] flex flex-col items-center mx-auto ">
        <h1 className="mt-[3.6rem] text-center text-[#353E44] font-[700] text-[1.4rem] md:text-[2.88rem] leading-[1.65rem] md:leading-[3.3rem] mx-auto w-full max-w-[60.56rem] ">
          {data.title}
        </h1>
      </div>
   
        <p className=" text-black font-[400] text-[1rem] md:text-[1.2rem] leading-[1rem] md:leading-[1.33rem] mx-auto w-full max-w-[41rem]">
          {data.desc}
        </p>
        <Link to="/signup">
          <button className=" mt-[0.8rem] px-[0.9em] py-[0.4em] bg-[#2293DF]  items-center text-[#FFFFFF] rounded-[0.6rem] font-[700] w-[14.5rem] h-[3rem] active:opacity-[0.5] ">
            {data.action}
          </button>
        </Link>
        {/* border-[3px] border-solid border-[red] backdrop-opacity  mt-[4.03rem] lg:mt-[8.06rem] mt-[0.94rem] mt-[1rem] text-38 leading-63  mt-[4.4rem] lg:mt-[8.8rem]  min-h-[17.6rem]*/}
        <div className="w-full min-h-[25rem] flex flex-col justify-end ">
          <div id="review" className="text-[#353E44] h-auto w-full max-w-[18.6rem] text-center flex flex-col items-center ">
            <p className="w-full font-[600] text-[1.23rem] leading-[1.23rem] opacity-[0.3] ">{data.review}</p>
            <p className="w-[56%] mt-[0.4rem] font-[600] text-[1.23rem] leading-[1.23rem] text-center ">{data.vendor}</p>
          </div>

        </div>
      </div>
    </div>
  );
};

export default HeroSection;
