import './App.css';

import { Routes, Route } from "react-router-dom";

import Individuals from './Pages/Individuals'
import Login from './Pages/Login'
import SignUp from './Pages/SignUp'
import BusinessSolutions from './Pages/BusinessSolutions'
import Merchants from './Pages/Merchants';
import Features from './Pages/Features';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Individuals />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="business-solutions" element={<BusinessSolutions />} />
        <Route path="merchants" element={<Merchants />} />
        <Route path="features" element={<Features />} />
        <Route path="individuals" element={<Individuals />} />
      </Routes>
    </div>
  );
}

export default App;
