import React from "react";
import { Link } from "react-router-dom";

const InfoCard = ({ data, images, style }) => {
  return (
    <div
      style={style}
      className="w-full min-h-[38.8rem] px-[3.5rem] md:px-[6.9rem] py-[2.5rem] md:py-[5rem] "
    >
      <div className="overflow-x-hidden items-center space-x-0 lg:space-x-[5.99rem] flex flex-col gap-y-[5.8rem] lg:gap-y-0  lg:flex-row">
        <div className="text-center md:text-left">
          <h1 className="text-[#000000] font-[600] text-[1.7rem] leading-[2.05rem] md:text-[3.4rem] md:leading-[4.1rem] w-auto max-w-[35.7rem] ">
            {data.title}
          </h1>
          <p className="mt-[1.8rem] text-[2] leading-[2] md:text-[2.3rem] md:leading-[2.9rem] font-[400] w-auto max-w-[24.19rem] ">
            {data.subtitle}
          </p>
          <p className="mt-[1.6rem] text-[1.1rem] leading-[1.6rem] font-[400] text-black w-auto max-w-[28.4rem]  ">
            {data.desc}
          </p>
          <Link to={data.path}>
            <button className="flex items-center mx-auto md:mx-0 mt-[2.7rem] bg-[#2293DF] rounded-[0.6rem] py-[0.9em] px-[1.4em] text-[#F6F9FB] text-[1.1rem] font-[700] leading-[1.7rem] gap-x-[1.9rem] active:opacity-[0.5] ">
              {data.action}
              <img src={images?.leftarrow} alt="" />
            </button>
          </Link>
        </div>
        <div className="">
          <img src={data.image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
