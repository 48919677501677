import React from 'react'
import InfoCard from '../../components/cards/InfoCard'

import mocks from './mocks'

const BuyNow = () => {
  const customstyle = {backgroundColor: '#F5F7FA' };
  return (
    <div>
        <InfoCard data={mocks.buyNow} images={mocks?.images} style={customstyle} />
    </div>
  )
}

export default BuyNow