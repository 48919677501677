import React from 'react'
import Main from '../../layouts/Main'

import PaymentNeeds from './PaymentNeeds'
import ExpensiveHardware from './ExpensiveHardware'
import GrowBusiness from './GrowBusiness'
import AcceptPayments from './AcceptPayments'


const Merchants = () => {
  return (
    <Main>
        <PaymentNeeds />
        <ExpensiveHardware />
        <GrowBusiness />
        <AcceptPayments />
    </Main>
  )
}

export default Merchants