import React from 'react'
import DesktopNav from '../components/DesktopNav'
import MobileNav from '../components/MobileNav'

import mocks from './mocks'

const AppHeader = () => {
  const bgColor = {backgroundColor: '#2293DF', }; 
  return (
    <div>
        <div className='hidden md:block'>
          <DesktopNav data={mocks.desktopNav} images={mocks?.images} style={bgColor} />
        </div>
        <div className='block md:hidden'>
          <MobileNav data={mocks.mobileNav} images={mocks?.images} />
        </div>
    </div>
  )
}

export default AppHeader