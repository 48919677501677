import React from "react";
import { Link } from "react-router-dom";

const SalesTerminal = ({ data, images }) => {
  return (
    <div className="mt-[2.8rem] px-[2.9rem] md:px-[6.4rem] overflow-x-hidden ">
      <h1 className="mx-auto text-center w-auto max-w-[52rem] text-[1.4rem] md:text-[2.8rem] leading-[1.6rem] md:leading-[3.2rem] text-black font-[700] ">
        {data.title}
      </h1>
      <div className="mt-[2.8rem] space-x-0 lg:space-x-[4.3rem] space-y-[3.5rem] lg:space-y-0 flex flex-col lg:flex-row items-center ">
        <div className="w-full  ">
          <img src={images?.onlinepayments} alt="" width={554} height={565} />
        </div>
        <div className="space-y-[3.3rem] ">
          {data.items.map((item) => (
            <div key={item.index} className="">
              <h2 className="font-[600] text-[1.2rem] md:text-[1.9rem] leading-[1.7rem] md:leading-[2.2rem] w-full max-w-[21.8rem] text-black ">
                {item.title}
              </h2>
              <p className="mt-[0.9rem] text-[1.1rem] leading-[2.1rem] text-[#353E44] w-full md:w-[37rem] font-[500]">
                {item.desc}
              </p>
              <Link to={item.path} className="cursor-pointer mt-[0.9rem] text-[1.1rem] leading-[1.7rem] text-[#75BD38] font-[700] ">
                {item.url}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SalesTerminal;
// [10.9rem] [18.5rem]
