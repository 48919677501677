import React from 'react'
import Merchants from '../views/merchants'

const MerchantsPage = () => {
  return (
    <div>
      <Merchants />
    </div>
  )
}

export default MerchantsPage