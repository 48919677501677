import React from "react";
import { Link, NavLink } from "react-router-dom";

const DesktopNav = ({ data, images, style }) => {
  const activeLink = "underline underline-offset-4 decoration-[#75BD38]";
  const defaultLink = " ";
  return (
    <div className="bg-white w-full  px-[1.8rem] py-[0.6rem]  flex justify-between items-center">
      {/*px-[7.63rem] pb-[1.38rem] h-[9.19rem]  */}
      <Link to="/">
        <img src={images.navlogo} alt="" />
      </Link>
      <div className="flex h-auto items-center">
        <nav className="flex ">
          {data.navs.map((nav, index) => ( 
            <NavLink
              to={nav.to}
              key={index}
              className={({isActive}) => (isActive ? activeLink : defaultLink)}
              // activeClassName="active-link hover:border-b-[0.1rem] active:opacity-[0.5]"
            >
              <p className="ml-[2.3rem] font-[500] text-[1.1rem] leading-[1.3rem] text-[#000000]  hover:underline underline-offset-4 decoration-[#75BD38] ">{nav.name}</p>
            </NavLink>
          ))}
        </nav>
        <div>
          <Link
            style={style}
            to="/login"
            className="flex items-center justify-center rounded-[0.625rem] text-white text-[1.123rem] leading-[1.7rem]  font-[700] w-[11.8rem] h-[3.5rem] py-[0.88rem] px-[1.3rem] ml-[5.378rem] active:opacity-[0.7] "
          >
            Sign In
            <img src={images?.leftarrow} alt="" className="ml-[1.06rem]" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DesktopNav;
