import bizheroimage from "../../assets/images/bizheroimage.png";
import visacard from "../../assets/images/visacard.png";
import fabric from "../../assets/images/fabric.png";
import girl from "../../assets/images/girl.png";
import trader from "../../assets/images/trader.png";
import onlinepayments from "../../assets/images/onlinepayments.png";
import leftarrow from "../../assets/svg/leftarrow.svg";
import navleft from "../../assets/svg/navleft.svg";
import navright from "../../assets/svg/navright.svg";

// eslint-disable-next-line
export default {
  images: {
    bizheroimage,
    leftarrow,
    onlinepayments,
    visacard,
    girl,
    fabric,
    trader,
    navright,
    navleft
  },
  heroSection: {
    title: "Get the tools to grow your business and increase your revenue.",
    desc: "Make and accept payments, manage sales records and cashflows, send invoices, manage inventory, and a lot more on MPayer Business.",
    action: "CREATE AN ACCOUNT",
  },
  salesTerminal: {
    title: "Transform any android mobile device into a Sales Terminal.",
    items: [
      {
        index: 1,
        title: "Fast  In-store/Online  Checkouts",
        desc: "Launch your business on the MPayer Business Network, Transform any mobile device into a payment terminal and start earning extra income and accepting payments from anywhere.",
        url: "Learn More >",
        path: '/merchants'
      },
      {
        index: 2,
        title: "Run Your Business From Anywhere",
        desc: "Scale your business, increase conversion rates and grow your bottom line by accepting payments from customers from any where in the world.",
        url: "Learn More >",
        path: '/merchants'
      },
    ],
  },
  collectPayments: {
    title: "Sell anything, anytime, anywhere. Collect payments fast.",
  },
  paymentCard1: {
    title: "In-Person Payments.",
    desc: "List your store on MPayer Business and join the contactless In-store payment revolution. Increase your customer walk-ins and daily sales with MPayer Merchant Payment App. ",
    action: "Learn More >",
    path: '/merchants',
    image: visacard,
    bgColor: "#2293DF",
  },
  paymentCard2: {
    title: "Secure Online Payments.",
    desc: "Let your customers shop, order & pay hassle-free. Mpayer Business provides secure UPI, card and wallet payment options which can easily be integrated on your app or website. ",
    action: "Learn More >",
    path: '/merchants',
    image: girl,
    bgColor: "#75BD38",
  },
  optionsAndChannels: {
    title: "Muliple Tools, Options and Channels.",
    subtitle: "Direct Invoicing.",
    desc: "Create beautiful, professional invoices and send them directly to your customers. Make getting paid a breeze. Integrate multiple payment options, track delinquent payments.",
    action: "Learn More >",
    path: '/merchants',
    image: fabric,
  },
  powerUp: {
    title: "Power up your business",
    subtitle: "Make and collect payments. Manage and grow your business",
  },

  // ------------ Add new carouselSlides object here, then pass it to CarouselCard in PowerUp. Object name should be unique.  -----------------
  carouselSlide:{
      header: "MPayer Business",
      desc: "Gain the advantage with tools and services to grow your business - loans and credit facilities, sales and inventory record management, customer management, employee management, direct invoicing, bulk payments and disbursements, bulk collections etc.",
      action: "Learn More >",
      path: '/merchants'
  },
  carouselSlide2:{
    header: "Bulk Payments/Transfers",
    desc: "Access to loans and credits to power up your small business. Access a phletora of  business tools and services including: sales and inventory record management, bulk disburseement, automatic  settlement, customer management, employee management, invoicing etc.",
    action: "Learn More >",
    path: '/merchants'
  }
    
};
