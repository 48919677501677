import React from "react";
import { Link } from "react-router-dom";

const PaymentCard = ({ data, images, style }) => {
  return (
    <div
      style={style}
      className="w-full max-w-[36.1rem] p-[1.32rem] md:p-[2.63rem]"
    >
      <div>
        <img src={data.image} alt="" width={492} height={323} />
      </div>
      <div className="mt-[1.75rem] ">
        <h2 className="font-[600] text-[#353E44] text-[1.88rem] leading-[2.15rem] w-full mb-[1.25rem] ">
          {data.title}
        </h2>
        <p className="inline font-[500] text-[1.123rem] leading-[2.13rem] ">
          {data.desc}
        </p>
        <Link to={data.path} >
          <span className="inline text-[#75BD38] font-[700] text-[0.92rem] leading-[1.73rem] cursor-pointer ">
            {data.action}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default PaymentCard;
