import React from 'react'
import { Link } from 'react-router-dom'

const CarouselCard = ({data, images}) => {
  return (
    <div className="relative p-[1.5rem] bg-[#2293DF] mt-[2.3rem] lg:mt-0 mr-[1rem] lg:mr-[2rem] min-w-[26rem] min-h-[25.5rem]">
        <h2 className="text-white text-[1rem] md:text-[1.88rem]leading-[1.08rem] md:leading-[2.123rem] font-[600] ">
          {data.header}
        </h2>
        <p className="mt-[1.25rem] text-white font-[500] text-[0.845rem] md:text-[1.125rem] leading-[1.7rem] md:leading-[2.129rem]">
          {data.desc}
        </p>
        <Link to={data.path} className="mt-[1rem] cursor-pointer text-white font-[700] text-[1.125rem] leading-[1.72rem] ">
          {data.action}
        </Link>
    </div>
  )
}

export default CarouselCard