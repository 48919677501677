import React from "react";
import mocks from "./mocks";

import CarouselCard from "../../components/cards/CarouselCard"; 

const PowerUp = ({ data, images }) => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 515;
    } else {
      current.scrollLeft += 515;
    }
  };

  return (
    <div className=" px-[3.8rem] md:px-[7.623rem] mt-[4.15rem] md:mt-[8.3rem] mb-[1.56rem] lg:mb-[3.125rem] w-full ">
      <h1 className="font-[700] text-black text-[1.38rem] md:text-[2.75rem] leading-[1.58rem] md:leading-[3.16rem]">
        {data.title}
      </h1>
      <p className="mt-[0.75rem] text-[#353E44] font-[500] text-[1.125rem] leading-[2.13rem]">
        {data.subtitle}
      </p>

      <div className="mt-[0.81rem] space-x-0 lg:space-x-[3rem] flex flex-col lg:flex-row items-center ">
        <div className="w-full lg:w-[60%]  ">
          <img src={images.trader} alt="" />
        </div>

          {/*------------------- CAROUSEL -----------------------------------*/}
          <div className="carousel-gallery flex my-[3rem] max-w-[100%] lg:max-w-[40%] relative ">
            <div ref={scrollRef} className="carousel-container flex w-[max-content] overflow-x-scroll   "  >
              <CarouselCard data={mocks.carouselSlide} images={mocks?.images} />
              <CarouselCard data={mocks.carouselSlide2} images={mocks?.images} />
              {/* <CarouselCard data={mocks.carouselSlide} images={mocks?.images} /> */}
            </div> 

            <div className="flex justify-center space-x-[1rem] w-full h-[50px] absolute bottom-0  ">
              <button onClick={() => scroll('left')} className="active:opacity-[0.5]">
                <img src={images.navleft} alt="" />
              </button>
              <button onClick={() => scroll('right')} className="active:opacity-[0.5]">
                <img src={images.navright} alt="" />
              </button>
            </div>
          </div>       
      </div>
    </div>
  );
};

export default PowerUp;
