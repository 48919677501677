
import React from 'react'
import { Link } from 'react-router-dom'

const MakePayments = ({data, images}) => {
  return (
    <div className='py-[3.48rem] px-[2.5rem] lg:px-[7.63rem] w-full flex flex-col items-center '>
      <h2 className='text-[#335533] font-[700] text-[1.2rem] md:text-[1.88rem] leading-[1.47rem] md:leading-[2.15rem] w-full max-w-[39rem] text-center '>{data.title}</h2>
      <p className='mt-[1.1rem] text-[#353E44] font-[500] text-[1rem] md:text-[1.123rem] leading-[2.09rem] md:leading-[2.213rem] w-full max-w-[54rem] text-center '>{data.desc}</p>
      <div className='mt-[4rem] flex w-full space-x-0 lg:space-x-[4.94rem] flex-col lg:flex-row items-center '>
        <div className=''>
          <div className=''> 
            <div className='absolute z-[-1] left-[-5rem] lg:top-[104.625rem] overflow-visible w-[80%] max-w-[44.4rem] min-h-[46.5rem] '>
              <img src={images.blob} alt=""  />
              {/* width={711} height={744} 99.625 hidden md:block */}
            </div>
          </div>
          <div className='w-full max-w-[35.6rem] '>
            <h3 className='font-[700] text-[#353E44] text-[1.88rem] leading-[2.7rem]  '>{data.subtitle} </h3>
            <p className='font-[500] text-[#353E44] text-[1.125rem] leading-[2.123rem] w-full '>{data.subdesc}</p>
            <div className='mt-[1.94rem] md:mt-[3.88rem]  '>
              {data.items.map(item => (
                <li key={item} className='flex items-center mb-[1.63rem] font-[500] text-[#353E44] text-[1.125rem] leading-[2.123rem] w-full '>
                    <img src={images.tick} alt="" className='mr-[1.25rem] ' />
                    <p>{item}</p>
                </li>
              ))}
              <Link to={data.path}>
                <button className='mt-[0.5remrem] active:opacity-[0.5] bg-[#2293DF] w-auto max-w-[17rem] h-[3.5rem] rounded-[0.5rem] py-[0.9rem] px-[1.4rem] text-white text-[1.13rem] leading-[1.7rem] font-[700] flex justify-center items-center '>
                  {data.action}
                  <img src={images.leftarrow} alt="" className='ml-[3.63rem]' />
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div>
          <img src={images.exchange} alt="" className='mt-[2.47rem] lg:mt-0 '/>
        </div>
      </div>
    </div>
  )
}

export default MakePayments