import React from 'react'
import HeroSection from './HeroSection'
import Services from './Services'
import ContactlessPayment from './ContactlessPayment'
import BuyNow from './BuyNow'
import SecurePayment from './SecurePayments'
import FinancialServices from './FinancialServices'
import MicroLending from './MicroLending'

import Main from '../../layouts/Main'
//import ContactlessPayment from './ContactlessPayment'

import mocks from './mocks'


const Individuals = () => {
  return (
    <Main>
        <HeroSection data={mocks.heroSection} images={mocks?.images} />
        <Services data={mocks.services} images={mocks?.images} />
        <ContactlessPayment/>
        <BuyNow />
        <SecurePayment />
        <FinancialServices />
        <MicroLending />
    </Main>
  )
}

export default Individuals