import React from 'react'
import mocks from './mocks'

import Alt from '../../layouts/Alt'

import HeroSection from './HeroSection'
import Services from '../individuals/Services'
import MakePayments from './MakePayments'
import EasyLife from './EasyLife'
import Purchases from './Purchases'

const Features = () => {
  return (
    <Alt>
        <HeroSection data={mocks.heroSection} images={mocks?.images} />
        <Services data={mocks.services} images={mocks?.images} />
        <MakePayments data={mocks.makePayments} images={mocks?.images}/>
        <EasyLife data={mocks.easyLife} images={mocks?.images} />
        <Purchases data={mocks.purchases} images={mocks?.images}/>
    </Alt>
  )
}

export default Features