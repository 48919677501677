import React from "react";
import { Link } from "react-router-dom";

const GetStarted = ({ data, images }) => {
  return (
    <div className="flex flex-col text-center items-center w-full min-h-[33.9rem] bg-white px-[1rem] py-[3.1rem] ">
      <h1 className="text-[#353E44] text-[3rem] md:text-[4rem] leading-[3.6rem] md:leading-[4.6rem] font-[700] ">
        {data.title} 
      </h1>
      <p className="text-[#353E44] text-[1.1rem] leading-[2.1rem] font-[500]">
        {data.subtitle}
      </p>
      <input type='text' className="mt-[2.3em] border border-[#CECECE] w-[54%] h-[4.2rem] rounded-[0.6rem] px-[1rem] focus:outline-none " />
      <p className="mt-[2.4em] text-[#353E44] text-[1.1rem] leading-[2.1rem] font-[700]">
        {data.desc}
      </p>
      <Link
        to="/signup"
        className="flex items-center mx-auto md:mx-0 mt-[2em] active:opacity-[0.5] bg-[#2293DF] font-[700] rounded-[0.6rem] py-[0.9em] px-[1.4em] text-[#F6F9FB] text-[1.1rem] leading-[1.7rem] gap-x-[1.9rem] "
      >
        {data.action}
        <img src={images?.leftarrow} alt="" />
      </Link>
    </div>
  );
};

export default GetStarted;
