import React from "react";

import { Link, NavLink } from "react-router-dom";

const MobileNav = ({ data, images }) => {
  //const navigate = useNavigate();
  const [showNav, setShowNav] = React.useState(false);
  const show = () => setShowNav(true);

  return (
    <div className=" ">
      <div className="w-full px-[1.8rem] bg-white py-[0.6rem] flex justify-between items-center  ">
        <Link to="/" >
          <img src={images.navlogo} alt="brandlogo" width={100} height={73} />
        </Link>
        <div onClick={show} className="cursor-pointer ">
          <img src={images.Hamburger} alt="navigationicon" />
        </div>
      </div>
      {showNav ? (
        <div className="fixed top-0 left-0 bg-[#00BAF2] p-[3rem] z-30 min-h-[100vh] w-full ">
          <div>
            <button
              onClick={() => setShowNav(false)}
              className="font-bold text-black text-[1.7rem] absolute top-[20px] right-[20px] "
            >
              X
            </button>
          </div>
          <div className="mt-[4rem] flex flex-col ">
            {data.navs.map((nav, index) => (
              <NavLink
                to={nav.to}
                key={index}
                className="mt-[2rem] font-[500] text-[2rem] leading-[2.3rem] text-[#000000] active:opacity-[0.5] hover:underline underline-offset-4 decoration-[#75BD38] "
              >
                {nav.name}
              </NavLink>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MobileNav;
