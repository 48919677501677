import React from 'react'

import mocks from './mocks'

import FooterNav from './FooterNav'
import GetStarted from './GetStarted'
import MoneyNeeds from './MoneyNeeds'


const Footer = () => {
  return (
    <div>
        <MoneyNeeds data={mocks.moneyNeeds} images={mocks?.images} /> 
        <GetStarted data={mocks.getStarted} images={mocks?.images} />
        <FooterNav data={mocks.footerNav} images={mocks?.images} />
    </div>
  )
}

export default Footer