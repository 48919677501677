import React from 'react'
import mocks from './mocks'

import MerchantsCard from '../../components/cards/MerchantsCard'

const PaymentNeeds = () => {
const customStyle = { top: '13rem', right: '4rem', } 
  return (
    <div className='mt-[4.32rem] md:mt-[8.63rem]'>
        <MerchantsCard data={mocks.paymentNeeds} images={mocks?.images} style={customStyle}  />
    </div>
  )
}

export default PaymentNeeds