import React from 'react'
import InfoCard from '../../components/cards/InfoCard'

import mocks from './mocks'

const SecurePayment = () => {
  const customstyle = {backgroundColor: 'white' };
  return (
    <div>
        <InfoCard data={mocks.securePayments} images={mocks?.images} style={customstyle} />
    </div>
  )
}

export default SecurePayment