import React from "react";

const Services = ({ data }) => {
  return (
    <div className="bg-[#00BAF2] px-[1.2rem] py-[3.1rem] w-full overflow-x-hidden min-h-[23.9rem] ">
      <h1 className="text-center text-[1.9rem] leading-[2.2rem] text-white font-[700] mb-[1rem] ">
        {data.title}
      </h1>
      <div className="flex flex-wrap mx-auto gap-x-[6.1rem] gap-y-[3.4rem] mt-[3.8em] max-w-[70.7rem] ">
        {data.options.map((option, index) => (
          <li key={index} className="list-none mx-auto flex gap-x-[0.8rem] ">
            <div className="bg-[white] rounded-[0.6rem] w-[4.3rem] h-[4rem] p-[0.4rem] flex items-center justify-center  ">
              <img src={option.icon} alt="" />
            </div>
            <p className="w-[6.8rem] text-white text-[1.1rem] leading-[1.6rem] font-[400] ">
              {option.title}
            </p>
          </li>
        ))}
      </div>
    </div>
  );
};

export default Services;
