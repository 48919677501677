import React from 'react'
import { Link } from 'react-router-dom'

const FooterNav = ({data, images}) => {
  return (
    <div className='min-h-[16.4rem] w-full bg-[#F6F9FB] px-[2.9rem] py-[1.3rem] '>
      <div className='mt-[1.9rem] md:mt-[3.8rem]'>
        <img src={images.Brand} alt="" width={147} height={38.19} />
      </div>
      <nav className='flex flex-col md:flex-row mt-[2.7rem] md:mt-[5.4rem] justify-between'>
        <div className='w-[50%] flex flex-col md:flex-row justify-between'>
          {data.navs.map((nav, index) => (
            <Link 
              to={nav.to}
              key={index} 
            >
              <p className="text-[#353E44] font-[700] text-[1rem] leading-[2.1rem] ">{nav.name}</p>
            </Link>
          ))}

            {/* <li key={item} className="list-none text-[#353E44] font-[700] text-[1rem] leading-[2.1rem] ">
            {item}
            </li> */}
          
        </div>
        <p className='text-[#353E44] font-[700] text-[1rem] leading-[2.1rem]'>{data.brandName}</p>
      </nav>
    </div>
  )
}

export default FooterNav