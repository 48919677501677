import React from "react";

const MoneyNeeds = ({ data, images }) => {
  return (
    <div
      style={{ backgroundImage: `url(${images?.Frame3})` }}
      // bg-no-repeat bg-cover bg-center
      className=" text-white px-[2.5rem] lg:px-[7.5rem] py-[2.4rem] lg:py-[4.8rem] bg-[#002447] min-h-[60.4rem] bg-no-repeat bg-cover bg-center"
    >
      <h2 className="font-[700] text-[1.9rem] leading-[2.15rem] w-full max-w-[28.9rem] ">
        {data.title}
      </h2>
      <p className="font-[500] text-[1.125rem] leading-[2.13rem] max-w-[26.2rem]  ">
        {data.desc}
      </p>
      <div className="flex flex-col md:flex-row mt-[1.8rem] space-x-0 md:space-x-[1.13rem] space-y-[1.13rem] md:space-y-0 w-full max-w-[9rem]">
        <img src={images.ios} alt="" className="cursor-pointer  " />
        <img src={images.androidstore} alt="" className="cursor-pointer rounded-[0.6rem]" />
      </div>
    </div>
  );
};

export default MoneyNeeds;
