import React from 'react'
import BusinessSolutions from '../views/business-solutions'

const BusinessSolutionsPage = () => {
  return (
    <div>
        <BusinessSolutions />
    </div>
  )
}

export default BusinessSolutionsPage